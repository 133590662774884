import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { SRLWrapper } from "simple-react-lightbox"
import { graphql, useStaticQuery } from "gatsby"

const Gallery = () => {
  const query = graphql`
    {
      allStrapiGalleries {
        nodes {
          id
          image_name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `

  const {
    allStrapiGalleries: { nodes: images },
  } = useStaticQuery(query)

  return (
    <section className="products container-fluid">
      <SRLWrapper>
        <Row>
          {images.map((image, i) => {
            const path = getImage(image?.image?.localFile)
            return (
              <Col key={i} lg={4}>
                <GatsbyImage
                  image={path}
                  alt={image.image_name}
                  className="product-img"
                />
              </Col>
            )
          })}
        </Row>
      </SRLWrapper>
    </section>
  )
}

export default Gallery
