import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Gallery from "../components/Gallery"
import Layout from "../components/Layout"
import Parallax from "../components/Parallax"
import Seo from "../components/Seo"
import VideoBanner from "../components/VideoBanner"
// import vid1 from "../assets/videos/vid1.mp4"

const About = ({ data }) => {
  const { strapiAboutPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="About" />
      <VideoBanner
        heading={info.Banner_heading}
        para={info.Banner_description}
        vid={info.Banner_video.url}
      />
      <Container className="my-5 text-center">
        <h3>{info.about_info1}</h3>
        <p className="text-muted">{info.about_info2}</p>
      </Container>
      <Parallax
        heading={info.about_heading1}
        para={info.about_description1}
        to="/who-we-are"
        para2
      />
      <h1 className="my-4 text-center">Photo Gallery</h1>
      <Gallery />
      <Parallax
        heading={info.about_heading2}
        para={info.about_description2}
        to="/what-we-do"
        isRight
        para1
      />
    </Layout>
  )
}

export const data = graphql`
  {
    strapiAboutPage {
      Banner_video {
        url
      }
      Banner_heading
      Banner_description
      about_info1
      about_info2
      about_heading1
      about_description1
      about_heading2
      about_description2
      about_description2
    }
  }
`

export default About
