import { Link } from "gatsby"
import React from "react"

const Parallax = ({ heading, para, to, isRight, para1, para2 }) => {
  return (
    <div className={`parallax ${para1 ? "para1" : para2 && "para2"}`}>
      <div className={`container text-white ${isRight && "det-right"}`}>
        <h1>{heading}</h1>
        <p>{para}</p>
        <Link to={to}>
          <button className="btn btn-yellow">VIEW MORE</button>
        </Link>
      </div>
    </div>
  )
}

export default Parallax
